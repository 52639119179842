import WordmarkSrc from '@/assets/wordmark-primary.svg';
import { RMSpacer } from '@/components/RMSpacer/RMSpacer';
import { RMText } from '@/components/RMText/RMText';

import { Anchor, Card, CardContainer, Container, Header, HeaderLogo } from './InvalidInvite.styles';

export function InvalidInvite() {
  return (
    <Container>
      <Header>
        <HeaderLogo src={WordmarkSrc} alt="Remento wordmark" />
      </Header>
      <CardContainer>
        <Card>
          <RMText type="serif" size="xl" color="on-surface-primary">
            This invite is no longer active.
          </RMText>
          <RMSpacer spacing="xl" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            Please check with the person who shared it with you to see if there is a new link available.
          </RMText>
          <RMSpacer spacing="2xl" direction="column" />
          <RMText type="sans" size="s" color="on-surface-primary">
            For additional questions, contact <Anchor href="mailto:support@remento.co">support@remento.co</Anchor>
          </RMText>
        </Card>
      </CardContainer>
    </Container>
  );
}
