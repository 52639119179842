import { styled } from '@linaria/react';

export const Container = styled.div`
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--spacing-2xl) var(--spacing-3xl);
  border-bottom: 1px solid var(--border-hairline);
  z-index: 1;

  body[data-mobile='true'] & {
    padding: var(--spacing-xl) var(--spacing-md);
  }
`;

export const HeaderLogo = styled.img`
  height: 1.5rem;

  body[data-mobile='true'] & {
    height: 1rem;
  }
`;

export const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: absolute;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--spacing-xl);
  max-width: 497px;
  border-radius: var(--radius-round);
  background-color: var(--surface-brightest);
  box-shadow: 0px 8px 16px -2px rgba(40, 62, 62, 0.12), 0px 2px 2px -1px rgba(40, 62, 62, 0.04);
`;

export const Anchor = styled.a`
  color: var(--primary);
`;
