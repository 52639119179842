import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { EntityType } from '@remento/types/entity';
import { UserOnboardingActionType } from '@remento/types/user';

import { PageLoader } from '@/components/PageLoader/PageLoader';
import { logger } from '@/logger';
import { OnboardingIntro, OnboardingStep } from '@/modules/onboarding/components/OnboardingIntro/OnboardingIntro';
import { getInviteSigninPath, getStoriesPath, RementoPage } from '@/modules/routing';
import { useServices } from '@/Services';
import { InviteTokenPayload } from '@/services/api/acl';
import { useOnboardingSteps } from '@/services/cms/onboarding/onboarding.service.hook';
import { OnboardingStepType } from '@/services/cms/onboarding/onboarding.types';
import { captureException } from '@/utils/captureException';
import { genitiveCase } from '@/utils/genitiveCase';

import { InvalidInvite } from '../../modules/sharing/components/InvalidInvite/InvalidInvite';

function replaceTokenVariables(text: string, tokenPayload: InviteTokenPayload): string {
  return text
    .replaceAll('{{storytellerFirstNameS}}', genitiveCase(tokenPayload.data.name.first))
    .replaceAll('{{storytellerFirstName}}', tokenPayload.data.name.first)
    .replaceAll('{{storytellerLastName}}', tokenPayload.data.name.last)
    .replaceAll('{{storytellerFullName}}', tokenPayload.data.name.full);
}

function getOnboardingStepTypeFromToken(token: InviteTokenPayload): OnboardingStepType | null {
  switch (token.data.projectType) {
    case undefined:
    case 'BIOGRAPHY':
      return 'collaborator.biography';
    case 'AUTOBIOGRAPHY':
      return 'collaborator.autobiography';
    case 'BABYBOOK':
      return 'collaborator.babybook';
  }
}

function InternalInvitePage() {
  const {
    userService,
    aclService,
    aclCacheService,
    redirectService,
    personCacheService,
    collaborationAnalyticsService,
    projectCacheService,
    entityCacheManagerService,
  } = useServices();

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  // Search params
  const inviteToken = searchParams.get('inviteToken');
  const inviteTokenPayload = useMemo(
    () => (inviteToken ? aclService.decodeInviteToken(inviteToken) : null),
    [aclService, inviteToken],
  );
  const fromSignin = searchParams.get('from-signin') === 'true';
  const newAccount = searchParams.get('new-account') === 'true';

  // Steps
  const onboardingStepsQuery = useOnboardingSteps(
    inviteTokenPayload ? getOnboardingStepTypeFromToken(inviteTokenPayload) : null,
  );
  const [activeStep, setActiveStep] = useState<OnboardingStep | null>(null);
  const steps = useMemo<OnboardingStep[] | null>(() => {
    if (!onboardingStepsQuery.data || !inviteTokenPayload) {
      return null;
    }

    return onboardingStepsQuery.data.map((step) => ({
      id: step.id,
      title: replaceTokenVariables(step.title, inviteTokenPayload),
      description: replaceTokenVariables(step.description, inviteTokenPayload),
      desktopAssetUrl: step.desktopAssetUrl,
      desktopAssetType: step.desktopAssetMimeType.includes('image') ? 'image' : 'video',
      mobileAssetUrl: step.mobileAssetUrl,
      mobileAssetType: step.mobileAssetMimeType.includes('image') ? 'image' : 'video',
    }));
  }, [inviteTokenPayload, onboardingStepsQuery.data]);

  // Set the initial step
  useEffect(() => {
    if (steps !== null) {
      setActiveStep(steps[fromSignin ? 1 : 0]);
    }
  }, [fromSignin, searchParams, steps]);

  // Load and validate the token
  const [tokenState, setTokenState] = useState<'loading' | 'valid' | 'invalid'>('loading');
  useEffect(() => {
    if (!inviteToken || !inviteTokenPayload || inviteTokenPayload.entity.type !== EntityType.PROJECT) {
      setTokenState('invalid');
      return;
    }

    const validateToken = async () => {
      const { valid } = await aclService.validateToken(inviteToken);
      if (!valid) {
        setTokenState('invalid');
        return;
      }

      // If there is a user logged in, we need to check if the user already
      // has access to the shared project and redirect directly to the stories page.
      const user = await userService.getUser();
      if (user !== null) {
        const userProjects = await projectCacheService.getProjects();

        if (userProjects.includes(inviteTokenPayload.entity.id)) {
          navigate(getStoriesPath(inviteTokenPayload.entity.id));
          return;
        }
      }

      // If the user was redirected from the sign-page, wes should consume the token immediately
      if (fromSignin) {
        await aclCacheService.joinGroup(inviteToken);
        collaborationAnalyticsService.onInvitationAccepted(
          inviteTokenPayload.inviteeId != null ? 'share-link' : 'email',
        );

        // And if it's a existing user, we can skip the onboarding and go directly to the stories page.
        if (user?.onboardingHistory.collaborator?.done === true) {
          navigate(getStoriesPath(inviteTokenPayload.entity.id));
          return;
        }
      }

      setTokenState('valid');
    };

    validateToken().catch((error) => captureException(error));
  }, [
    aclCacheService,
    collaborationAnalyticsService,
    aclService,
    fromSignin,
    inviteToken,
    inviteTokenPayload,
    navigate,
    newAccount,
    personCacheService,
    searchParams,
    userService,
    projectCacheService,
  ]);

  // Callbacks
  const handleNextStep = useCallback(async () => {
    if (!steps || !activeStep || !inviteToken || !inviteTokenPayload) {
      return;
    }

    const index = steps.findIndex((s) => s.id === activeStep.id);

    // Consume the token if the user is signed-in, or redirect the user to the signin.
    if (index === 0 && fromSignin === false) {
      const user = await userService.getUser();
      if (user !== null) {
        await aclCacheService.joinGroup(inviteToken);
        collaborationAnalyticsService.onInvitationAccepted(
          inviteTokenPayload.inviteeId != null ? 'share-link' : 'email',
        );
        await entityCacheManagerService.mutate(
          userService.createSetUserOnboardingHistoryMutation(user, UserOnboardingActionType.COLLABORATOR),
        );
        await userService.refreshUser();
        navigate(getStoriesPath(inviteTokenPayload.entity.id));
        return;
      }

      const inviteSearchParams = new URLSearchParams();
      inviteSearchParams.append('inviteToken', inviteToken);
      inviteSearchParams.append('from-signin', 'true');
      await redirectService.registerRedirect('signed-in', '/invite?' + inviteSearchParams.toString());
      navigate(getInviteSigninPath({ backupLocalData: true, inviteToken }));
      return;
    }

    // If it's on the last step, go th the stories page
    if (index === steps.length - 1) {
      const user = await userService.getUser();
      if (user == null) {
        // This will never happen
        logger.error('USER_CANNOT_BE_NULL');
        return;
      }
      await entityCacheManagerService.mutate(
        userService.createSetUserOnboardingHistoryMutation(user, UserOnboardingActionType.COLLABORATOR),
      );
      await userService.refreshUser();

      // Invalidate projects' cache
      projectCacheService.invalidateProjectsCache();

      navigate(getStoriesPath(inviteTokenPayload.entity.id));
      return;
    }

    // Otherwise, just go th the next step
    setActiveStep(steps[index + 1]);
  }, [
    steps,
    activeStep,
    inviteToken,
    inviteTokenPayload,
    fromSignin,
    userService,
    redirectService,
    navigate,
    aclCacheService,
    collaborationAnalyticsService,
    entityCacheManagerService,
    projectCacheService,
  ]);

  const handlePreviousStep = useCallback(() => {
    if (!steps || !activeStep) {
      return;
    }

    const index = steps.findIndex((s) => s.id === activeStep.id);
    if (index > 0) {
      setActiveStep(steps[index - 1]);
    }
  }, [activeStep, steps]);

  if (!inviteToken || tokenState === 'invalid') {
    return <InvalidInvite />;
  }

  if (!steps || !activeStep || tokenState === 'loading') {
    return <PageLoader />;
  }

  return (
    <OnboardingIntro
      activeStep={activeStep}
      steps={steps}
      onNextStep={handleNextStep}
      onPreviousStep={handlePreviousStep}
      onChangeStep={setActiveStep}
    />
  );
}

export function InvitePage() {
  return (
    <RementoPage type="default">
      <InternalInvitePage />
    </RementoPage>
  );
}
